/*
  Main JS
  
  $$ Last modified:  19:26:22 Sun Oct  8 2023 CEST
*/

$(document).ready(function(){

    /* Smooth Scrolling (cf. https://css-tricks.com/snippets/jquery/smooth-scrolling/) */
    // Select all links with class .scrolllink
    $('.scrolllink')
    // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
                    && 
                    location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function() {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });

    /* Mini-Logo animation (requires ScrollMagic) */
    // init controller
    var controller1 = new ScrollMagic.Controller({
        globalSceneOptions: {
            triggerHook: 'onLeave',
            duration: 100
        }
    });

    // show small logo

    new ScrollMagic.Scene({triggerElement: 'nav'})
        .on("enter", function(e){
            $("#logo-sm").css("opacity", "1.0");
        })
        .addTo(controller1);

    new ScrollMagic.Scene({triggerElement: 'header'})
        .on("enter", function(e){
            $("#logo-sm").css("opacity", "0.0");
        })
        .addTo(controller1);

    // ----------------------------------------
    // ----------------------------------------
    // Image Slideshow
    // RP  Sun Oct  8 17:41:16 2023
    // ----------------------------------------
    // append the .dimmed class to all images
    //$(".imageSlideshow figure").addClass("dimmed");
    

    // ----------------------------------------
    // ----------------------------------------
});
